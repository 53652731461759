<template>
  <div>
    <div class='md-layout md-size-100 '>
      <div v-if="mode!=='!read'" class='md-layout-item btn-row'>
        <md-button v-if='tableRows.length>0'
                   :disabled="ElementDublicateIndexes.length + ElementIncorrectConditionIndexes.length>0"
                   class='md-success' @click='addInspection'>
          {{ $t('button.save') }}
        </md-button>
      </div>
    </div>
    <div class='md-layout md-size-100 md-alignment-center-right'>
      <div class='md-layout-item'>
        <InspectionLink :asset-id='bid' :asset-type="'bridge'" :inspection-type="'condition'"/>
      </div>
    </div>
    <div class='md-layout md-size-100 '>
      <div class='md-layout-item md-small-size-100 '>
        <md-card>
          <md-card-content>
            <!--div class="md-layout-item md-small-size-100 md-size-30">
              <md-datepicker
                  v-model="dateOfInspection"
                  md-immediately>
                <label>
                  {{ $t("bridges.date_inspection") }}
                </label>
              </md-datepicker>
            </div-->

            <md-table v-model='tableRows' md-model-id="index" md-fixed-header :md-height="500">
              <md-table-row slot='md-table-row' slot-scope='{ item, index }'
              >
                <md-table-cell :md-label="$t('bridges.condition_element')"
                               :md-tooltip="$t('bridges.condition_element')"
                               :style='{fontWeight:500}'>
                  <div class="row-v-align">
                    <md-button
                        class="md-just-icon md-success md-mini"
                        :class="[ElementDublicateIndexes.includes(index)?'md-danger':'md-success']"
                        @click="onAddElement(index)">
                      <md-icon>add</md-icon>
                    </md-button>
                    <md-button
                        class="md-just-icon  md-mini"
                        :class="[ElementHasPhoto.includes(item.element + ' ' + item.location)?'md-primary':'md-default']"
                        @click="onOpenPhotos(index)">
                      <md-icon>photo_library</md-icon>
                    </md-button>
                    {{ $t(`bridge.element_${item['element']}`) }}
                  </div>
                </md-table-cell>
                <md-table-cell
                    :md-label="$t('bridges.condition_location')"
                    :md-tooltip="$t('bridges.condition_location')">
                  <md-field
                      :class="[{ 'md-error': ElementDublicateIndexes.includes(index) }]">
                    <md-input v-model='tableRows[index].location'></md-input>
                  </md-field>
                </md-table-cell>
                <md-table-cell
                    :md-label="$t('bridges.area_or_quantity')"
                    :md-tooltip="$t('bridges.area_or_quantity')">
                  <md-field>
                    <md-input v-model='tableRows[index].area_or_quantity'>
                    </md-input>
                  </md-field>
                </md-table-cell>
                <md-table-cell :md-label="$t('bridges.unit')"
                               :md-tooltip="$t('bridges.unit')">
                  <md-field>
                    <md-select v-model="tableRows[index].unit" name="unit" id="unit">
                      <md-option v-for="type in unitTypes" :value="type" :key="type">{{ type }}</md-option>
                    </md-select>
                  </md-field>
                </md-table-cell>
                <md-table-cell
                    v-for='(csItem) in [1,2,3,4]'
                    :key='csItem'
                    :md-label='$t(`bridges.cs${csItem}`)'
                    :md-tooltip='$t(`bridges.cs${csItem}`)'
                >
                  <md-field :class="{'cell-has-alert':ElementIncorrectConditionIndexes.includes(index)}">
                    <md-input :id='`cs-${index}-${csItem}`' v-model='tableRows[index][`cs${csItem}`]'
                              :name='`cs-${index}-${csItem}`' max='100' min='0' step='5' type='number'></md-input>
                  </md-field>
                  <!--option v-for="type of csCollection" :value="type" :key="`${csItem}-${type}`">{{ type }}
                  </option-->

                </md-table-cell>
                <md-table-cell
                    :md-label="$t('bridges.smart_flag')"
                    :md-tooltip="$t('bridges.smart_flag')">
                  <md-switch v-model='tableRows[index].smart_flag' class='md-success'>
                  </md-switch>
                </md-table-cell>
                <md-table-cell
                    :md-label="$t('bridges.smart_flag_reason')"
                    :md-tooltip="$t('bridges.smart_flag_reason')">
                  <md-field>
                    <md-input v-model='tableRows[index].smart_flag_reason'>
                    </md-input>
                  </md-field>
                </md-table-cell>
                <md-table-cell
                    :md-label="$t('bridges.damaged')"
                    :md-tooltip="$t('bridges.damaged')">
                  <md-switch v-model='tableRows[index].damaged' class='md-success'>
                  </md-switch>
                </md-table-cell>
                <md-table-cell
                    :md-label="$t('bridges.comment')"
                    :md-tooltip="$t('bridges.comment')">
                  <md-field>
                    <md-input v-model='tableRows[index].comment'></md-input>
                  </md-field>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
    </div>

    <div class='md-layout md-size-100 '>
      <div v-for='imgItem in imagesSrc' :key='imgItem'
           class='md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-33'>
        <ImageCard>
          <img slot='imageState'
               :src="imagesForElement(imgItem).length>0?imagesForElement(imgItem)[0].src:'/img/road.png'" alt=''>
          <h4 slot='title' class='title'>
            {{ $t(`bridge.${imgItem}`) }}
          </h4>
          <template slot='first-button'>
            <md-button class='md-simple md-just-icon' @click='viewCustomFiles(imagesForElement(imgItem)[0].src)'
                       :disabled="imagesForElement(imgItem).length===0">
              <md-icon>art_track</md-icon>
              <md-tooltip md-direction='bottom'>View</md-tooltip>
            </md-button>
          </template>
          <template slot='second-button'>
            <md-button class='md-simple md-just-icon md-success' @click='selectCustomFiles(imgItem)'>
              <md-icon>edit</md-icon>
              <md-tooltip md-direction='bottom'>Edit</md-tooltip>
            </md-button>
          </template>
          <template slot='third-button'>
            <md-button class='md-simple md-just-icon md-danger' @click='clearCustomFiles(imgItem)'>
              <md-icon>close</md-icon>
              <md-tooltip md-direction='bottom'>Remove</md-tooltip>
            </md-button>
          </template>
        </ImageCard>
      </div>
    </div>

    <input
        id='overviewImage'
        ref='overview'
        :accept="'image/*'"
        class='custom-file-input'
        type='file'
        @change='addOverviewImageFile($event)'
    />
    <input
        id='surfaceImage'
        ref='surface'
        :accept="'image/*'"
        class='custom-file-input'
        type='file'
        @change='addSurfaceImageFile($event)'
    />
    <input
        id='undersideImage'
        ref='underside'
        :accept="'image/*'"
        class='custom-file-input'
        type='file'
        @change='addUndersideImageFiles($event)'
    />
    <md-dialog :md-active.sync='showElementPhotoCatalog'>
      <md-dialog-content class="md-layout-item md-size-100">
        <div style="display: flex">
          <div class="md-headline card-title"> {{ $t("bridges.photos") | UpCase }} {{ `(${photoForElement})` }}</div>
          <md-button v-if="!isReadOnly" :title="$t('button.add')" @click.prevent="selectPhotoFiles"
                     class="md-success md-icon-button">
            <md-icon>add</md-icon>
          </md-button>
        </div>
        <md-card>
          <md-card-content>
            <div v-for="(row, index) in Math.round(imagesForElement(photoForElement).length/2)" :key="row"
                 class="md-layout-item md-size-100 inspection-photo-catalog">
              <div :style="{background: `url(${imagesForElement(photoForElement)[index*2].src})`, cursor:'pointer'}"
                   class="md-layout md-layout-item md-size-50 md-large-size-100 inspection-image"
                   @click="viewCustomFiles(imagesForElement(photoForElement)[index*2].src)">
              </div>
              <div v-if="imagesForElement(photoForElement).length > index*2+1"
                   :style="{background: `url(${imagesForElement(photoForElement)[index*2+1].src})`, cursor:'pointer'}"
                   class="md-layout md-layout-item md-size-50 md-large-size-100 inspection-image"
                   @click="viewCustomFiles(imagesForElement(photoForElement)[index*2+1].src)">
              </div>
            </div>
          </md-card-content>
        </md-card>
        <md-dialog-actions>
          <md-button class="md-info md-simple md-icon-button" @click="showElementPhotoCatalog = false">{{ $t('button.close') }}</md-button>
        </md-dialog-actions>
      </md-dialog-content>
    </md-dialog>

    <md-dialog :md-active.sync='showImageFullSize'>
      <md-dialog-content>
        <img :src="showImageUrl!==''?showImageUrl:'/img/road.png'"></md-dialog-content>
    </md-dialog>
    <input
        id="photos"
        ref="photos"
        :accept="'image/*'"
        class="custom-file-input"
        type="file"
        @change="addPhotos($event)"
    />

  </div>
</template>
<script>
import Swal from 'sweetalert2'
import ImageCard from '@/components/image-card'
import InspectionLink from '@/components/Inspection-Link'

export default {
  name: '',
  props: {
    mode: String
  },

  data() {
    //let dateFormat = this.$material.locale.dateFormat || 'yyyy-MM-dd'
    let now = new Date()
    return {
      bid: this.$route.params.bid,
      iid: this.$route.params.iid ? this.$route.params.iid : 'last',
      photoCatalog: [],
      showElementPhotoCatalog: false,
      isReadOnly: false,
      photoForElement: "",
      dateOfInspection: now,
      tableRows: [],
      unitTypes: [],
      //csCollection: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100]
      imagesSrc: ['overview', 'surface', 'underside'],
      showImageFullSize: false,
      showImageUrl: ''
    }

  },
  filters: {
    UpCase: function (value) {
      return value.toUpperCase()
    }
  },
  components: {
    ImageCard,
    InspectionLink
  },
  async created() {
    this.$store.dispatch("LOAD_APP_UNIT_TYPES").then((list) => {
      this.unitTypes = [...list]
    })

    const condList = await this.$store.dispatch('LOAD_BRIDGE_CONDITION_DATA', {
      bridge_id: this.bid, inspection_id: this.iid
    })

    const payload = {
      inspection_id: this.iid,
      inspection_type: 'conditions',
      inspection_asset_type: 'bridge',
      inspection_asset_id: this.bid
    }
    this.$store.dispatch("LOAD_BMS_INSPECTION_FILES", payload).then((images) => {
      images.forEach(item => {
        this.photoCatalog.push({file: "dropFiles[0]", src: item.file_url, element: item.inspection_element})
      })

    })

    this.tableRows = [...condList]
  },
  methods: {
    imagesForElement(element) {
      const elementsPhotos = this.photoCatalog.filter(item => item.element === element)
      return elementsPhotos ? elementsPhotos : []
    },
    onOpenPhotos(index) {
      this.showElementPhotoCatalog = true
      this.photoForElement = this.tableRows[index].element + ' ' + this.tableRows[index].location
    },
    onAddElement(index) {
      const newRow = {...this.tableRows[index]}
      newRow.location = ""
      this.tableRows = [...this.tableRows.slice(0, index + 1), {...newRow}, ...this.tableRows.slice(index + 1)]
    },
    clearCustomFiles(dest) {
      this.imagesSrc[dest] = ''
    },
    viewCustomFiles(src) {
      this.showImageFullSize = true
      this.showImageUrl = src
    },
    selectCustomFiles(dest) {
      //this.$refs.photos.click()
      this.$refs[dest].click()
    },
    selectPhotoFiles(elem) {
      this.$refs.photos.click()
    },
    addPhotos(evt) {
      const dropFiles = [...evt.target.files]
      dropFiles.forEach((file) => {
        const src = URL.createObjectURL(file)
        this.photoCatalog.push({file, src, element: this.photoForElement})
      })
    },
    addOverviewImageFile(evt) {
      const dropFiles = [...evt.target.files]
      if (dropFiles.length) {
        this.photoCatalog.push({file: dropFiles[0], src: URL.createObjectURL(dropFiles[0]), element: "overview"})
        //this.imagesSrc.overview.file = dropFiles[0]
        //this.imagesSrc.overview.src = URL.createObjectURL(dropFiles[0])
      }
    },
    addSurfaceImageFile(evt) {
      const dropFiles = [...evt.target.files]
      if (dropFiles.length) {
        // this.imagesSrc.surface.file = dropFiles[0]
        // this.imagesSrc.surface.src = URL.createObjectURL(dropFiles[0])
        this.photoCatalog.push({file: dropFiles[0], src: URL.createObjectURL(dropFiles[0]), element: "surface"})
      }
    },
    addUndersideImageFiles(evt) {
      const dropFiles = [...evt.target.files]
      if (dropFiles.length) {
        // this.imagesSrc.underside.file = dropFiles[0]
        // this.imagesSrc.underside.src = URL.createObjectURL(dropFiles[0])
        this.photoCatalog.push({file: dropFiles[0], src: URL.createObjectURL(dropFiles[0]), element: "underside"})
      }
    },
    addInspection() {
      const alert = {
        type: 'success',
        text: this.$t(`bridge.inspection_was_added`),
        footer: ''
      }
      const inspectionData = this.tableRows.map(row => {
        const {
          area_or_quantity,
          element,
          location,
          cs0,
          cs1,
          cs2,
          cs3,
          cs4,
          damaged,
          comment,
          unit,
          smart_flag,
          smart_flag_reason
        } = row
        return {
          area_or_quantity: Number(area_or_quantity),
          element,
          location,
          cs0: Number(cs0),
          cs1: Number(cs1),
          cs2: Number(cs2),
          cs3: Number(cs3),
          cs4: Number(cs4),
          damaged,
          comment,
          unit,
          smart_flag,
          smart_flag_reason
        }
      })
      //const formData = new FormData();
      this.$store.dispatch('ADD_BRIDGE_CONDITION_INSPECTION', {
        bridge_id: this.bid,
        data: inspectionData
      }).then((inspId) => {
        this.photoCatalog.forEach(async item => {
          let formData = new FormData()
          formData.append('files', item.file)
          formData.append('inspection_type', 'conditions')
          formData.append('inspection_id', inspId)
          formData.append('inspection_asset_type', 'bridge')
          formData.append('inspection_element', item.element)
          formData.append('inspection_asset_id', this.bid)
          try {
            await this.$store.dispatch('ADD_INSPECTION_FILE', formData)
          } catch (err) {
            alert.type = 'error'
            alert.text = this.$t(`plan.photo_was_not_added`)
            alert.footer = err.response ? err.response.data : this.$t(err.message)
            Swal.fire(alert)
          }
        })
        Swal.fire(alert).then(() => {
          //this.touched.category = false
          this.$nextTick(() => this.$validator.reset())
        })

      }, err => {
        alert.type = 'error'
        alert.text = this.$t(`plan.inspection_was_not_added`)
        alert.footer = err.response ? err.response.data : this.$t(err.message)
        Swal.fire(alert)
      })
    }
  },
  computed: {
    ElementIncorrectConditionIndexes() {
      const indexes = this.tableRows.reduce((a, e, ind) => {
        if ((parseInt(e.cs1) + parseInt(e.cs2) + parseInt(e.cs3) + parseInt(e.cs4)) > 100) {
          a.push(ind)
        }
        return a
      }, [])
      return indexes
    },
    ElementDublicateIndexes() {
      const indexes = []
      const lookup = this.tableRows.reduce((a, e) => {
        a[e.element + e.location] = ++a[e.element + e.location] || 0;
        return a;
      }, {});

      this.tableRows.forEach((item, index) => {
        if (lookup[item.element + item.location]) {
          indexes.push(index)
        }
      })
      return indexes
    },
    ElementHasPhoto() {
      return this.photoCatalog.map(item => item.element)
    }
  }
}

</script>
<style lang='scss' >
@import '@/assets/scss/md/_variables.scss';

.md-table-cell{
  min-width: 130px;
}
.md-table-fixed-header-container table{
  table-layout:fixed;
}


.md-table-head-label {
  height: auto;
  min-width: 130px;
  max-width: 130px;
  //padding-right: 32px;
  //padding-left: 2px;
  display: inline-block;
  position: relative;
  line-height: 20px;
}

.md-dialog .md-dialog-container {
  max-width: 768px;
}

.row-v-align {
  display: flex;
  align-items: center;
  gap: 10px;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  button {
    margin-left: 10px
  }
}

.custom-file-input {
  display: none;
}

//.md-dialog /deep/ .md-dialog-container {
.md-dialog .md-dialog-container {
  height: auto;
  max-width: 1024px;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain
  }
}

.card-title {
  padding-right: 50px;
}

.cell-has-alert {
  border-bottom: 1px solid red;
}

.toolbar-icon {
  position: absolute;
  right: 10px;
  top: 15px;
  font-size: 25px;

  cursor: pointer;
  display: flex;

  div {
    padding-left: 10px;
    color: $gray-color;

    &:hover {
      color: $brand-primary;
    }
  }
}

.inspection-photo-catalog {
  display: flex;
  justify-items: center;
  padding: 5px 0;

  .inspection-image {
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-position: center !important;
    height: 300px;
    margin: 5px;
  }
}
</style>
